/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.nav-icon {
  margin-right: 8px;
  color: #555;
}

.nav-item {
  margin: 18px 0px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.spacer {
  flex: 1 1 auto;
}

.grid-container {
  display: grid;
  grid-gap: 20px;
  padding: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: start;
}

.grid-title {
  font-weight: bold;
  margin-bottom: 10px;
}

spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 4px;
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.toast-message {
  position: fixed;
  top: 64px;
  right: 90px;
  z-index: 99;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.align-end {
  align-items: end;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.padding-16 {
  padding: 16px;
}

.padding-8 {
  padding: 8px;
}

.margin-8 {
  margin: 8px;
}
